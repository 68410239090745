<ul class="navbar-nav float-right border-left profile-bar">
    <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)" (click)="openPlugin()">
            <img src="assets/img/header-icons/spurt-connect.svg" alt="Spurt Connect">
        </a>
    </li>
    <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)" [appHideIfUnauthorized]="'settings'"
            [routerLink]="['/settings']" [routerLinkActive]="'active'">
            <img src="assets/img/settings-ico.png" alt="user">
        </a>
    </li>
    <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)" [appHideIfUnauthorized]="'settings'"
            [routerLink]="['/settings']" [routerLinkActive]="'active'">
            <img src="assets/img/group-ico-sm.png" alt="user">
        </a>
    </li>

    <li class="nav-item dropdown" style="cursor: default" ngbDropdown placement="bottom-right">
        <a class="nav-link dropdown-toggle waves-effect waves-dark pro-pic" style="cursor: default"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

            <span *ngIf="newMessageCount" class="NewMessge">{{newMessageCount}}</span>

            <span>{{(layoutSandbox.user$ | async)?.firstName}}</span>
            <div style="cursor: pointer" class="pro-pic-dropdown">
                <img ngbDropdownToggle
                    [src]="imageUrls + '?path=' + (layoutSandbox.user$ | async)?.avatarPath + '&name=' + (layoutSandbox.user$ | async)?.avatar +'&width=60&height=60'"
                    onError="this.src='./assets/img/avatar-img1.png';" class="w3-circle" alt="Alps1" style="width: 55px;
    height: 55px;">

            </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right user-dd animated top-settings-menu" ngbDropdownMenu>

            <a class="dropdown-item" [routerLink]="['/../../editprofile']">
                <i class="ti-user m-r-5 m-l-5"></i>{{'Profile.user.EditProfile'| translate}} </a>
            <a class="dropdown-item" href="javascript:void(0)" [routerLink]="['/change-password']">
                <i class="ti-wallet m-r-5 m-l-5"></i>{{'Profile.user.ChangePassword'| translate}} </a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="openChat()">

                <i class="ti-comments m-r-5 m-l-5"></i>Messages

                <span *ngIf="newMessageCount" class="NewMessge1"></span></a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout.emit($event)">
                <i class="fa fa-power-off m-r-5 m-l-5"></i>{{'Profile.user.Logout'| translate}} </a>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>