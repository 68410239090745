<!-- <div class="auth-wrapper">
    <div class="logo-left">
        <img src="assets/img/logo-v22.png">
        <h3> Welcome </h3>
        <p>
             <span>
                Spurt Demo
            </span>- technology and thoughtfulness combine for a seamless e-commerce
        </p>
    </div> -->
<!--Left-->


<!-- ============================================================== -->
<!-- Only router without any element -->
<!-- ============================================================== -->
<!-- <router-outlet></router-outlet>
</div> -->

<div class="marg">
    <div class="login">
        <div class="row">
            <div class="col-md-7 col-sm-6">
                <img src="assets/img/spurt_v4_admin_login.png" class="spurt-img">
              <!--   <div class="txt">A quick and easy Node JS and Angular eCommerce solution to develop an awesome e-commerce Website</div> -->
            </div>
            <div class="col-md-5 col-sm-6">
                <router-outlet></router-outlet>

            </div>
        </div>

    </div>
    <div class="ttt"> Premierstreets © 2021. All Rights Reserved.</div>
</div>