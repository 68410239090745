<div class="chatbox-social">
	<div class="row justify-content-between no-gutters">

		<div *ngIf="!id" class="col-md-4 col-lg-4 pb-100">
			<div class="people-list" id="people-list">

				<div class="chat-header">
					<div class="chat-about">
						<div class="chat-with">Recent</div>
					</div>
				</div>

				<ul class="list">
					<ng-container *ngIf="chats.length > 0">
						<li *ngFor="let chat of chats" (click)="setCurrentChat(chat)" class="clearfix"
							[ngClass]="{'active': (chat.chatId === currentChat.chatId)}">
							<img *ngIf="chat.avatar" class="avtar"
								[src]="imagePath + '?path=' + chat.avatarPath + '&width=40&height=40' + '&name=' + chat.avatar"
								alt="" />
							<img *ngIf="!chat.avatar" src="assets/img/socialuser-sm.jpg" alt="" class="avtar" />
							<div class="about">
								<div class="name">{{chat.firstName + ' ' + chat.lastName}} <div
										*ngIf="chat.lastMessageTime" class="dates">{{chat.lastMessageTime | date:
										'MM/dd/yyyy'}}</div>
								</div>
								<div class="status">
									{{chat.lastMessage ? chat.lastMessage: ""}}
								</div>
							</div>
							<div *ngIf="chat.unreadCount" class="new-message">{{chat.unreadCount}}</div>
						</li>
					</ng-container>
				</ul>
			</div>
		</div>

		<div class="mt-0 my-md-0" [ngClass]="{ 'col-md-12': id, 'col-md-8': !id }">
			<div class="chat">
				<div *ngIf="currentChat" class="chat-header clearfix">
					<img *ngIf="currentChat.avatar"
						[src]="imagePath + '?path=' + currentChat.avatarPath + '&width=40&height=40' + '&name=' + currentChat.avatar"
						alt="" />
					<img *ngIf="!currentChat.avatar" src="assets/img/socialuser-sm.jpg" alt="" />

					<div *ngIf="currentChat.firstName" class="chat-about">
						<div class="chat-with">
							{{currentChat.firstName + ' ' + currentChat.lastName}}
							<span class="chat-names"> <span *ngIf="currentChat.lastMessageTime"
									class="small">({{relativeTime(currentChat.lastMessageTime)}})</span>
							</span>
						</div>

					</div>
				</div> <!-- end chat-header -->

				<div class="chat-history">
					<div *ngIf="videoChat" class="video-chat-container">
						<div class="video-chats">
							<div id="videos">
								<div #subscriberDiv id="subscriber"></div>
								<div #publisherDiv id="publisher"></div>
							</div>
							<div class="uk-shoo-social">
								<ul class="d-flex align-items-center">
									<li>
										<a href="javascript:void(0);" class="btn-uks btn-wish-uk btn-hovers">
											<img src="assets/img/full-screen.svg" alt="">
										</a>
									</li>

								</ul>
							</div>
						</div>
					</div>
					<ul *ngIf="!videoChat" #scrollMe>
						<li *ngFor="let messageData of messages" style="margin-bottom: 10px;"
							[ngClass]="{'clearfix': (messageData.user.id == currentUser.userId) ? 1: 0 }">

							<div class="datetime-grp" *ngIf="messageData.type">{{messageData.date}}</div>
							<div *ngIf="!messageData.type" class="media">
								<img *ngIf="(messageData.fromAdmin == 0) && messageData.user.avatar" class="avtar-smm"
									[src]="imagePath + '?path=' + messageData.user.avatarPath + '&width=40&height=40' + '&name=' + messageData.user.avatar"
									alt="" />
								<img *ngIf="(messageData.fromAdmin == 0) && (!messageData.user.avatar)"
									src="assets/img/socialuser-sm.jpg" alt="" class="avtar-smm" />
								<div class="media-body">
									<div class="message other-message"
										[ngClass]="{'float-right': (messageData.fromAdmin) ? 1 : 0  }">
										{{messageData.message}}
									</div>
									<div class="message-data"
										[ngClass]="{'align-right': (messageData.fromAdmin) ? 1 : 0  }">
										<span class="message-data-time">{{messageData.createdDate | date: 'h:mm
											a'}}</span>
									</div>
								</div>
							</div>
						</li>
					</ul>

				</div> <!-- end chat-history -->

				<div class="chat-message clearfix">
					<textarea [(ngModel)]="message" (keydown.enter)="$event.preventDefault();sendMessage()"
						name="message-to-send" id="message-to-send" placeholder="Type your message here..."
						rows="1"></textarea>

					<button class="btn-snds" (click)="sendMessage()"><img src="assets/img/send.svg" alt=""></button>

				</div> <!-- end chat-message -->

			</div> <!-- end chat -->
		</div>
	</div>
</div> <!-- end container -->