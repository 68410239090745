// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // baseUrl: 'https://app.premierstreets.com:8000/api',
  // imageUrl: 'https://images.premierstreets.com:3000/resize',
 // imageBaseUrl: 'https://app.premierstreets.com:8000',
  // productUrl: 'https://app.premierstreets.com:8000/uploads',
  // baseUrl: 'http://localhost:8002/api',s
  // imageUrl: 'http://localhost:8002/api/media/image-resize',

  baseUrl: 'https://psapi.miraclechd.co/api',
  imageUrl: 'https://psapi.miraclechd.co/api/media/image-resize',
  imageBaseUrl: 'https://psapi.miraclechd.co',
  productUrl: 'https://psapi.miraclechd.co/uploads',
  uploadUrl: 'https://psapi.miraclechd.co/uploads',
  //productUrl: 'https://localhost:8000/uploads',
  pluginUrl: '<Your API url>',
  vendorUrl: 'https://vendor.miraclechd.co/',
  //vendorUrl: 'https://localhost:4201',
  socketUrl: "https://io.premierstreets.com"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.