<form novalidate [formGroup]="editProfileForm">
    <div class="row products-section">
        <div class="col-12 layout-mar">
            <div class="breadcrumbs-row noborder">
                <div class="bc-col">
                    <ul>
                        <li> {{'Profile.Auth.EditProfile' | translate}}</li>
                    </ul>
                </div>
                <div class="bc-col-btn ap-col-btn">
                    <button class="btn btn-save" (click)="save(editProfileForm.value)"><img
                            src="assets/img/tick-round-white.png">{{'Profile.Auth.Save' | translate}}
                    </button>
                    <button (click)="editprofileCancel()" class="btn btn-cancel"><img
                            src="assets/img/close-white-ico.png"> {{'Profile.Auth.Cancel' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <!--Breadcrumbs-->
    </div>

    <div class="flex addproduct-wrap addgroup-wrap">

        <div class="card ap-general">
            <div class="flex ap-body ap-edit-body">
                <div class="ap-body-col">
                    <div class="form-group">
                        <label>{{'Profile.Auth.Name' | translate}} <sup>*</sup></label>
                        <input type="text" class="form-control" placeholder="Name" formControlName="name"
                            [ngClass]="{'validationcolor':editProfileForm.get('name').hasError('required') && editProfileForm.get('name').touched  &&ifSubmitted==true}">

                        <div class="error"
                            *ngIf="(editProfileForm.get('name').hasError('required') && editProfileForm.get('name').touched)  &&ifSubmitted==true">
                            {{'Profile.Error.NameRequired' | translate}}
                        </div>
                        <div class="error"
                            *ngIf="(editProfileForm.get('name').hasError('pattern') && editProfileForm.get('name').touched)  &&ifSubmitted==true">

                            {{'Profile.Error.IncorrectUserEmail' | translate}}
                        </div>
                    </div>
                </div>
                <!--Col-->
                <div class="ap-body-col">
                    <div class="form-group">
                        <label>{{'Profile.Auth.Email' | translate}} <sup>*</sup></label>
                        <input type="text" class="form-control" placeholder="{{'Profile.Auth.Email' | translate}} "
                            formControlName="email"
                            [ngClass]="{'validationcolor':editProfileForm.get('email').hasError('required') && editProfileForm.get('email').touched  &&ifSubmitted==true}">

                        <div class="error"
                            *ngIf="(editProfileForm.get('email').hasError('required') && editProfileForm.get('email').touched)  &&ifSubmitted==true">
                            {{'Profile.Error.EmailRequired' | translate}}
                        </div>
                        <div class="error"
                            *ngIf="(editProfileForm.get('email').hasError('pattern') && editProfileForm.get('email').touched)  &&ifSubmitted==true">

                            {{'Profile.Error.IncorrectEmail' | translate}}
                        </div>
                    </div>
                </div>
                <!--Col-->

                <div class="ap-body-col">
                    <div class="form-group">
                        <label>{{'Profile.Auth.PhoneNumber' | translate}} <sup>*</sup> </label>
                        <input type="text" class="form-control" maxlength="10" appOnlyNumber
                            placeholder="{{'Profile.Auth.PhoneNumber' | translate}}" formControlName="phone"
                            [ngClass]="{'validationcolor':editProfileForm.get('phone').hasError('required') && editProfileForm.get('phone').touched  &&ifSubmitted==true}">
                        <div class="error"
                            *ngIf="(editProfileForm.get('phone').hasError('required') && editProfileForm.get('phone').touched)  &&ifSubmitted==true">
                            {{'Profile.Error.PhoneNumberRequired' | translate}}
                        </div>
                        <div class="error"
                            *ngIf="(editProfileForm.get('phone').hasError('pattern') && editProfileForm.get('phone').touched)  &&ifSubmitted==true">
                            {{'Profile.Error.IncorrectNumber' | translate}}
                        </div>
                    </div>
                </div>
                <!--Col-->
                <div class="ap-body-col">
                    <div class="form-group">
                        <label>{{'Profile.Auth.Address' | translate}}<sup>*</sup></label>
                        <textarea class="form-control" rows="2" placeholder="{{'Profile.Auth.Address' | translate}}"
                            formControlName="address"
                            [ngClass]="{'validationcolor':editProfileForm.get('address').hasError('required') && editProfileForm.get('address').touched  &&ifSubmitted==true}"></textarea>

                        <div class="error"
                            *ngIf="(editProfileForm.get('address').hasError('required') && editProfileForm.get('address').touched)  &&ifSubmitted==true">
                            {{'Profile.Error.Addressrequired' | translate}}
                        </div>
                    </div>
                </div>
                <div class="ap-body-col">
                    <div class="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" class="custom-control-input" id="checkbox1" formControlName="isOnline" />
                        <label class="custom-control-label" for="checkbox1">Available for Chat?</label>
                    </div>
                </div>

                <!--Col-->
                <div class="ap-body-col">
                    <div class="form-group">
                        <label> {{'Profile.Auth.profileImage' | translate}} <sup>*</sup></label>
                        <div class="flex avatar-wrap">
                            <div class="avathar-img">
                                <img [src]="image" onError="this.src='./assets/img/avatar-img1.png';"
                                    style="width: 100%; height: 100%">
                            </div>
                            <div class="avathar-file">
                                <input type="file" #filePath (change)="docufile($event)">
                            </div>
                            <a href="javascript:void(0)" (click)='uploadButtonClick()'>{{'Profile.Auth.Change' |
                                translate}}</a>

                        </div>

                    </div>
                </div>
                <!--Col-->
            </div>
        </div>
        <!--General-->
    </div>
</form>
<!--<app-profile-bar  [userDetails]="profileData"></app-profile-bar>-->